<template>
  <div>
    <Header />
    <pageTitle txt="追加発注 週間種類別集計 詳細" :backBtn="backBtn" />
    <div id="meta">
      <div
        v-on:click="
          print = !print;
          setPrint();
        "
        class="meta-btn"
      >
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <ul class="product">
            <li class="date"></li>
            <li
              class="product-item"
              v-for="product in list[0].products"
              :key="product.id"
            >
              {{ product.name }}
            </li>
          </ul>
        </li>
        <orderWeeklyDetailItem
          v-for="(order, key) in list"
          :key="key"
          class="weekday"
          :month="order.month"
          :date="order.day"
          :day="key"
          :orderNum="key"
          :products="order.products"
          :dayName="order.weekName"
          :class="{
            saturday: order.weekName == '土',
            sunday: order.is_holiday == '1' || order.weekName == '日'
          }"
          :extra="true"
        />
      </ul>
    </div>
    <div id="overlay" :class="{ visible: print }">
      <div
        class="print-paper landscape"
        v-for="paper in this.printNum"
        :key="paper"
      >
        <div class="print-inner">
          <div id="print-meta">
            <p class="print-ttl">追加発注 週間種類別集計詳細</p>
            <p class="print-period">
              {{ $route.query.stYear }}年{{ $route.query.stMonth }}月{{
                $route.query.stDay
              }}日 ～ {{ $route.query.endMonth }}月{{ $route.query.endDay }}日
            </p>
            <div class="print-time">
              {{ today }} 現在
              <br />
              {{ paper }}/{{ printNum }}頁
            </div>
          </div>

          <ul class="list">
            <li class="list-head">
              <ul class="product">
                <li class="date"></li>
                <li
                  class="product-item"
                  v-for="product in list[0].products.slice(
                    paper * dataPerPage - dataPerPage,
                    paper * dataPerPage
                  )"
                  :key="product.id"
                >
                  {{ product.name }}
                </li>
              </ul>
            </li>
            <orderWeeklyDetailItemPrint
              v-for="(order, key) in list"
              :key="key"
              class="weekday"
              :month="order.month"
              :date="order.day"
              :day="key"
              :orderNum="key"
              :products="order.products"
              :dataPerPage="dataPerPage"
              :index="paper"
              :pagePerItems="pagePerItems"
              :dayName="order.weekName"
              :class="{
                saturday: order.weekName == '土',
                sunday: order.is_holiday == '1' || order.weekName == '日'
              }"
              :extra="true"
            />
          </ul>
        </div>
      </div>

      <div id="fixedMenu">
        <div
          v-on:click="
            print = !print;
            removeSetPrint();
          "
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #fcfcfc;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}

.meta-btn {
  margin-left: auto;
}

.noorder {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  width: 145px;
  height: 30px;

  box-sizing: border-box;
  padding-left: 12px;

  color: variables.$scrollbar-back;
  @extend .f_body;
  border: solid 1px variables.$scrollbar-back;
  opacity: 0.2;

  margin-left: auto;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: variables.$scrollbar-back transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  }

  &.positive {
    color: variables.$negative;
    border: solid 1px variables.$negative;
    opacity: 1;
    &:after {
      border-color: variables.$negative transparent transparent transparent;
    }
  }
}

.lock {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 125px;
  height: 30px;
  padding-left: 15px;
  box-sizing: border-box;

  color: variables.$secondary;
  border: solid 1px variables.$secondary;
  @extend .f_body;

  cursor: pointer;
  &.locked {
    color: #fff;
    background: variables.$scrollbar-back;
    border-color: variables.$scrollbar-back;
  }

  .icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.lock-item {
  opacity: 0;
  animation: fade 0.4s ease forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.print {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  margin-bottom: 20px;
  display: flex;
}

.list-head {
  width: 160px;
  min-width: 160px;
}

.date {
  height: 60px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.product-item {
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#print-meta {
  margin-bottom: 20px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderWeeklyDetailItem from "@/components/iseya/orderWeeklyDetailItem";
import orderWeeklyDetailItemPrint from "@/components/iseya/orderWeeklyDetailItemPrint";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "extraWeeklyDetail",
  components: {
    Header,
    pageTitle,
    orderWeeklyDetailItem,
    orderWeeklyDetailItemPrint,
    btnS,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      lock: false,
      print: false,
      today: "",
      list: [],
      rowNum: 1, //店舗数 / カラム数
      dataPerRow: 7, //1ページあたりのカラム数
      dataPerPage: 10, //1ページあたりのデータ数
      pagePerItems: 1, //商品1周分で何枚必要か
      printNum: 1 //印刷枚数 = 商品数 / dataPerPage * rowNum
    };
  },
  async created() {
    const param = {
      start_date:
        this.$route.query.stYear +
        "-" +
        this.$route.query.stMonth +
        "-" +
        this.$route.query.stDay
    };
    const api = new ApiClient();
    const result = await api.post("/iseya/order/weekly_data", param);
    this.list = result.list;

    this.rowNum = 7;
    this.printNum =
      parseInt(this.list[0].products.length / this.dataPerPage) + 1;
    this.pagePerItems = parseInt(
      this.list[0].products.length / this.dataPerPage
    );
  },
  mounted() {
    //今日の日付
    let getDate = new Date();
    let y = getDate.getFullYear();
    let m = getDate.getMonth() + 1;
    let d = getDate.getDate();
    this.today = y + "年" + m + "月" + d + "日";
  },
  methods: {
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-landscape");
      document.body.classList.add("print-landscape");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-landscape");
      document.body.classList.remove("print-landscape");
    }
  }
};
</script>
