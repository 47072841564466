var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('pageTitle',{attrs:{"txt":"追加発注 週間種類別集計 詳細","backBtn":_vm.backBtn}}),_c('div',{attrs:{"id":"meta"}},[_c('div',{staticClass:"meta-btn",on:{"click":function($event){_vm.print = !_vm.print;
        _vm.setPrint();}}},[_c('btnS',{staticClass:"print",attrs:{"btnTxt":"印刷","color":"primary"}})],1)]),_c('div',{attrs:{"id":"wrapper"}},[_c('ul',{staticClass:"list"},[_c('li',{staticClass:"list-head"},[_c('ul',{staticClass:"product"},[_c('li',{staticClass:"date"}),_vm._l((_vm.list[0].products),function(product){return _c('li',{key:product.id,staticClass:"product-item"},[_vm._v(" "+_vm._s(product.name)+" ")])})],2)]),_vm._l((_vm.list),function(order,key){return _c('orderWeeklyDetailItem',{key:key,staticClass:"weekday",class:{
          saturday: order.weekName == '土',
          sunday: order.is_holiday == '1' || order.weekName == '日'
        },attrs:{"month":order.month,"date":order.day,"day":key,"orderNum":key,"products":order.products,"dayName":order.weekName,"extra":true}})})],2)]),_c('div',{class:{ visible: _vm.print },attrs:{"id":"overlay"}},[_vm._l((this.printNum),function(paper){return _c('div',{key:paper,staticClass:"print-paper landscape"},[_c('div',{staticClass:"print-inner"},[_c('div',{attrs:{"id":"print-meta"}},[_c('p',{staticClass:"print-ttl"},[_vm._v("追加発注 週間種類別集計詳細")]),_c('p',{staticClass:"print-period"},[_vm._v(" "+_vm._s(_vm.$route.query.stYear)+"年"+_vm._s(_vm.$route.query.stMonth)+"月"+_vm._s(_vm.$route.query.stDay)+"日 ～ "+_vm._s(_vm.$route.query.endMonth)+"月"+_vm._s(_vm.$route.query.endDay)+"日 ")]),_c('div',{staticClass:"print-time"},[_vm._v(" "+_vm._s(_vm.today)+" 現在 "),_c('br'),_vm._v(" "+_vm._s(paper)+"/"+_vm._s(_vm.printNum)+"頁 ")])]),_c('ul',{staticClass:"list"},[_c('li',{staticClass:"list-head"},[_c('ul',{staticClass:"product"},[_c('li',{staticClass:"date"}),_vm._l((_vm.list[0].products.slice(
                  paper * _vm.dataPerPage - _vm.dataPerPage,
                  paper * _vm.dataPerPage
                )),function(product){return _c('li',{key:product.id,staticClass:"product-item"},[_vm._v(" "+_vm._s(product.name)+" ")])})],2)]),_vm._l((_vm.list),function(order,key){return _c('orderWeeklyDetailItemPrint',{key:key,staticClass:"weekday",class:{
              saturday: order.weekName == '土',
              sunday: order.is_holiday == '1' || order.weekName == '日'
            },attrs:{"month":order.month,"date":order.day,"day":key,"orderNum":key,"products":order.products,"dataPerPage":_vm.dataPerPage,"index":paper,"pagePerItems":_vm.pagePerItems,"dayName":order.weekName,"extra":true}})})],2)])])}),_c('div',{attrs:{"id":"fixedMenu"}},[_c('div',{on:{"click":function($event){_vm.print = !_vm.print;
          _vm.removeSetPrint();}}},[_c('btnS',{attrs:{"btnTxt":"戻る","color":"back"}})],1),_c('div',{attrs:{"onclick":"print()"}},[_c('btnL',{attrs:{"btnTxt":"この内容で印刷する","color":"primary"}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }